import { Avatar, Box, Card, CardMedia,  Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ReactNode } from 'react';

interface Props {
    pageTitle?: string;
    coverImage? : string;
    avatarImage? : string;
    avatarAlt?: string;
    children: ReactNode;

}

function MediaCard(props: Props) {

    return (
            <Card elevation={0} sx= {{minHeight : "100vh"}}>
                <CardMedia
                    component="img"
                    sx={{ height: 540, }}
                    image={props.coverImage}
    
                >
                </CardMedia>
                <Box height={0}>
                    <Typography
                        variant='h1'
                        style={{
                            position: "relative", top: "-350px",
                            color: "white",
                            textShadow: "-5px 5px 10px black, -10px 5px 5px black, -5px 10px 5px black"
                        }}
                        align='center'
                    >
                        {props.pageTitle}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="start"
                >
                    {props.avatarImage ? 
                    <Grid md={2} xs={12} paddingLeft={"5vw"} paddingRight={"5vw"} height={100}>
                        
                        <Avatar
                            src={props.avatarImage}
                            alt={props.avatarAlt}
                            style={{
                                position: "relative", top: "-100px",
                                border: 'solid',
                                color: "white",
                            }}
                            
                            sx={{ width: "200px", height: "200px", margin: "auto" }} />
                    </Grid>
                    : <></>}
    
    
                    <Grid xs={10} paddingLeft={"5vw"} paddingRight={"5vw"}>
                        <Box sx={{ display: { md: "block", xs: "none" } }} height={50} />
                        {props.children}
    
                    </Grid>
                </Grid>
            </Card>
    
        );
}

export default MediaCard;