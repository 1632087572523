import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Header from './components/Header';
import { Outlet } from 'react-router';
import Theme from './theme/MainTheme'

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}> 
        <CssBaseline />
        <Header />
        <main>
            <Outlet/>
        </main>
      </ThemeProvider>

    </React.Fragment>
  );
}

export default App;
