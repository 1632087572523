
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import HomePage from './components/Pages/HomePage';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import App from './App'
const MainRouter = () => {
      const router = createBrowserRouter([
        {
            path: "/",
            element: <App/>,
            children: [
                {
                  path: "/",
                  element: <HomePage/>,
                  
                },
                {
                  path: "/about",
                  element: <AboutPage/>,
                },
                {
                  path: "/contact",
                  element: <ContactPage/>,
                },

            ]
        },
      ]);
      
    return (
        <RouterProvider router={router} />
    )
}
export default MainRouter;