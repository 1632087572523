import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { ReactNode } from 'react';
interface Props {
    items: Array<ReactNode>
}
function TwoColumnGrid(props: Props) {
    return (<>
        <Grid container>
            {props.items.map((item, index) => (
                <React.Fragment key={index}>
                    <Grid sm={index % 2 === 0 ? 5 : 6} xs={12}>
                        {item}
                    </Grid>
                    {((index + 1) === props.items.length) ?
                        <></>
                        :
                        <>
                            {index % 2 === 0 ?
                                <>
                                    <Grid sm={1} sx={{ display: { xs: "none", sm: "block" } }}>
                                        <Divider orientation='vertical' variant='fullWidth'></Divider>
                                    </Grid>
                                    <Grid xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
                                        <Divider orientation='horizontal'></Divider>
                                    </Grid>
                                </>
                                :

                                <>
                                    <Grid sm={5} xs={12}>
                                        <Divider orientation='horizontal'></Divider>
                                    </Grid>
                                    <Grid sm={1} sx={{ display: { xs: "none", sm: "block" } }}>
                                        <Divider orientation='vertical'></Divider>
                                    </Grid>
                                    <Grid sm={5} sx={{ display: { xs: "none", sm: "block" } }}>
                                        <Divider orientation='horizontal'></Divider>
                                    </Grid>
                                </>
                            }
                        </>
                    }
                </React.Fragment>
            ))}
        </Grid>
    </>);
}
export default TwoColumnGrid;