import { Box} from '@mui/material';
import AboutDetails from '../AboutDetails';
import ContactDetails from '../ContactDetails';
import MediaCard from '../MediaCard';

function HomePage() {

    return (
        <MediaCard
            coverImage={process.env.PUBLIC_URL + '/images/Cover.jpg'}
            avatarImage={process.env.PUBLIC_URL + '/images/avatar/majd.jpg'}
            avatarAlt="Majd Ghada"
            pageTitle="Majd Ghada"
        >
            <AboutDetails />

            <Box height={"2rem"} />

            <ContactDetails />

        </MediaCard>

    );
}

export default HomePage;


