
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  Container,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Link as RouterLink,
} from 'react-router-dom'
import React from 'react';
import strings from '../strings';

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  let baseUrl = ``;
  const links = [
    { text: strings.About, path: `${baseUrl}/about` },
    { text: strings.Contact, path: `${baseUrl}/contact` },
  ];

  const allLinks = [
    { text: strings.Home, path: `${baseUrl}/` },
    ...links
  ];

  const homeButtonFull =
    <Typography
      variant="h6"
      noWrap
      to='/'
      component={RouterLink}
      sx={{
        mr: 2,
        display: { xs: 'none', md: 'flex' },
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      {strings.Home}
    </Typography>;
  const menuButtonSmall = <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
    <IconButton
      size="large"
      onClick={handleOpenNavMenu}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >

      {allLinks.map((link) => (
        <MenuItem 
        key={link.path} 
        >

                <Typography
                  variant="h6"
                  component={RouterLink}
                  onClick={handleCloseNavMenu}
                  to={link.path}
                  noWrap
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {link.text}
                </Typography>
        </MenuItem>
      ))}
    </Menu>
  </Box>;
  const homeButtonSmall = <>
    <Typography
      variant="h5"
      noWrap
      component={RouterLink}
      to='/'
      sx={{
        mr: 2,
        display: { xs: 'flex', md: 'none' },
        flexGrow: 1,
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      {strings.Home}
    </Typography>
  </>;
  const navigationLinksFull = () =>
  
    links.map((link) => (
          <Typography
            variant="h6"
            onClick={handleCloseNavMenu}
            component={RouterLink}
            key={link.path}
            to={link.path}
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {link.text}
          </Typography>
    ));
  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ gap: '2rem'}} >
          {homeButtonFull}
          {navigationLinksFull()}

          {menuButtonSmall}
          {homeButtonSmall}

        </Toolbar>
      </Container>
    </AppBar>

  );
}
export default Header;