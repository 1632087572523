import { Typography } from '@mui/material';
import MuiMarkdown from 'mui-markdown';
import strings from '../strings';
import React from 'react';
import TwoColumnGrid from './TwoColumnGrid';

function AboutDetails() {
    const sections = [
        { title: strings.AboutEducationTitle, contentMd: strings.AboutEducationContentMd },
        { title: strings.AboutLanguagesTitle, contentMd: strings.AboutLanguagesContentMd },
        { title: strings.AboutWorkTitle, contentMd: strings.AboutWorkContentMd },
    ];
    return (<>
        <Typography
            variant='h3'
        >
            {strings.AboutMe}
        </Typography>
        <TwoColumnGrid items=
            {sections.map((section, index) => (
                <React.Fragment key={index}>
                    <Typography variant='h4'>
                        {section.title}
                    </Typography>
                    <MuiMarkdown>
                        {section.contentMd}
                    </MuiMarkdown>
                </React.Fragment>
            ))}
        />

    </>);
}
export default AboutDetails;