import { createTheme } from '@mui/material/styles';

const MainTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#021405',
    },
    secondary: {
      main: '#5c0202',
    },
    background: {
      default: "#303030",
      paper: "#424242"
    }
  },
  
  typography:{
    allVariants:{
      fontFamily: "VT323",
      color: "white"
    },
    body1: {
      fontSize: "1.5rem",
      fontFamily: "VT323"
    },
    body2: {
      fontSize: "1rem",
      fontFamily: "VT323"
    }
    
  }
});
export default MainTheme