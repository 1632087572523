import ContactDetails from '../ContactDetails';
import MediaCard from '../MediaCard';


function ContactPage() {

    return (
        <MediaCard
            coverImage={process.env.PUBLIC_URL + '/images/Cover.jpg'}
            avatarImage={process.env.PUBLIC_URL + '/images/avatar/majd.jpg'}
            avatarAlt="Majd Ghada"
            pageTitle="Majd Ghada"
        >
            <ContactDetails />

        </MediaCard>

    );
}

export default ContactPage;