import AboutDetails from "../AboutDetails";
import MediaCard from "../MediaCard";

function AboutPage() {
    return (
        <MediaCard 
            coverImage={process.env.PUBLIC_URL + '/images/Cover.jpg'}
            avatarImage={process.env.PUBLIC_URL  + '/images/avatar/majd.jpg'}
            avatarAlt="Majd Ghada"
            pageTitle="Majd Ghada"
        >
            <AboutDetails />

        </MediaCard>
    );

}

export default AboutPage;