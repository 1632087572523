
const strings = {
    Home: "Home",
    About: "About",
    Contact: "Contact",
    // TODO: move to a separate file
    AboutDetailsMd: `
# Majd Ghada
## Software Engineer
asdfasdfas

    asdffasdfasd

    `,
    AboutMe: "About Me:",
    AboutEducationTitle: "Education:",
    AboutEducationContentMd: `
BSC. in Informatics Engineering

Graduated in 2017

Tishreen University - Latakia, Syria
    `,
    AboutWorkTitle: "Work Experience:",
    AboutWorkContentMd: `
2017 May - 2022 Dec: 

NCR Corporation - Prague, Czech Republic

2023 Jan - May: 

Pure Storage - Prague, Czech Republic

2023 June - Present: 

Second Foundation - Prague, Czech Republic
    `,
    AboutLanguagesTitle: "Languages:",
    AboutLanguagesContentMd: `
Arabic: Native

English: Proficient (~C2)

Czech: Beginner (~A2)
    `,
    ContactDetails: "Contact Details:",
    LinkedInUrl:"https://www.linkedin.com/in/majd-ghada-5b95b474/",
    LinkedInLinkText:"linkedin.com/in/majd-ghada-5b95b474",
    FacebookUrl:"https://www.facebook.com/majd.gda1/",
    FacebookLinkText:"facebook.com/majd.gda1",
    GitHubUrl:"https://github.com/majdghada/",
    GitHubLinkText:"github.com/majdghada",
    EmailAddressLink: "mailto:majd@majdghada.com",
    EmailAddressText: "majd@majdghada.com",
    TelegramUrl: "https://t.me/MajdMG",
    TelegramLinkText: "t.me/MajdMG",

}
export default strings;