import { Link, Stack, SvgIcon, Typography } from '@mui/material';
import strings from '../strings';
import { LinkedIn, Email, Facebook, GitHub, Telegram } from '@mui/icons-material';
import TwoColumnGrid from './TwoColumnGrid';
function ContactDetails() {
    const sections = [
        { icon: Email, text: strings.EmailAddressText, link: strings.EmailAddressLink },
        { icon: GitHub, text: strings.GitHubLinkText, link: strings.GitHubUrl },
        { icon: LinkedIn, text: strings.LinkedInLinkText, link: strings.LinkedInUrl },
        { icon: Facebook, text: strings.FacebookLinkText, link: strings.FacebookUrl },
        { icon: Telegram, text: strings.TelegramLinkText, link: strings.TelegramUrl },
    ];
    return (<>
        <Typography
            variant='h3'
        >
            {strings.ContactDetails}
        </Typography>
        <TwoColumnGrid items = 
            {sections.map((section, index) => (
                <Stack key={index} direction="row" alignItems="center" spacing={".5rem"}>
                    <SvgIcon component={section.icon} sx={{ fontSize: "2rem" }} > </SvgIcon>
                    <Typography
                        variant='body1'
                        component={Link}
                        href={section.link}
                        target='_blank'
                    >
                        {section.text}
                    </Typography>
                </Stack>
            ))}
        />
    </>);
}
export default ContactDetails;